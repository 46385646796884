import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  active?: boolean
  category: string
  onClick?: (e: any) => void
  title: string
}

export const Filter = memo(function Filter({
  active = false,
  category,
  onClick,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container
      className={active ? 'active' : undefined}
      data-category={category}
      onClick={onClick}
    >
      {title}
    </Container>
  )
})

const Container = styled.div`
  border: 0.0625rem solid ${({ theme }) => theme.colors.variants.primaryDark};
  border-radius: 1.875rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.9375rem;
  line-height: 1.1875rem;
  margin: 0 0.375rem;
  padding: 0.75rem 2.75rem 0.5625rem;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${rgba(theme.colors.variants.primaryDark, 0.1)};
  }
  &.active {
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    color: ${({ theme }) => theme.colors.variants.neutralLight5};
  }

  @media (max-width: 1199px) {
    padding: 0.625rem 2.25rem 0.4375rem;
  }
`
